<template>
  <v-card outlined
    ><v-card-title class="text-h6"
      >{{ $tc('product.name', 1) }} {{ $t('common.details') }}</v-card-title
    >
    <v-list v-if="itemProduct.data"
      ><v-list-item v-for="(prop, i) in props" :key="i">
        <v-list-item-content>{{ $t(`product.${prop}`) }}</v-list-item-content>
        <v-list-item-content class="font-weight-bold">{{
          itemProduct.data.attributes.customAttributes[prop] || '-'
        }}</v-list-item-content></v-list-item
      >
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ itemProduct: 'itemProduct/item' })
  },
  data() {
    return {
      props: [
        'code',
        'alias',
        'model',
        'style',
        'description',
        'ownerFirstName',
        'ownerLastName'
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
