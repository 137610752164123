<template>
  <v-card outlined>
    <v-card-title>
      {{ $t('common.relatedInfo') }}

      <v-spacer></v-spacer>
      <v-btn
        v-if="isEditable"
        class="mx-1 float-right"
        color="primary"
        depressed
        :loading="isLoading"
        @click="onSubmit"
        >{{ $t('actions.save') }}</v-btn
      ><v-btn class="float-right" light depressed @click="onEdit">{{
        isEditable ? $t('common.close') : $t('actions.edit')
      }}</v-btn>
    </v-card-title>

    <v-card-text v-if="isEditable">
      <v-row v-for="file in files" :key="file.label" dense>
        <v-col cols="12" md="6">
          <BaseFieldInput
            v-model="file.url"
            dense
            placeholder="https://..."
            :label="file.name"
        /></v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-else-if="itemProduct.data">
      <v-row dense
        ><v-col cols="8">
          <v-list
            v-for="file in itemProduct.data.attributes.customAttributes.files"
            :key="file.label"
            dense
          >
            <v-list-item>
              <v-list-item-content>
                {{ file.name }}
              </v-list-item-content>
              <v-list-item-content class="font-weight-bold">
                {{ file.url || $t('common.empty') }}
              </v-list-item-content>
            </v-list-item>
          </v-list></v-col
        ></v-row
      >
    </v-card-text>
  </v-card>
</template>
<script>
import BaseFieldInput from '@/components/fields/BaseFieldInput'
import { mapActions } from 'vuex'
export default {
  name: 'ItemProductRelatedInfo',
  components: { BaseFieldInput },
  props: {
    itemProduct: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isEditable: false,
      isLoading: false,
      files: []
    }
  },
  mounted() {},
  methods: {
    ...mapActions({ itemProductUpdate: 'itemProduct/update' }),
    onEdit() {
      if (this.isEditable) return (this.isEditable = false)
      this.files =
        this.itemProduct.data.attributes.customAttributes.files ||
        this.createFilesArray()
      this.isEditable = true
    },
    async onSubmit() {
      this.isLoading = true
      const itemProduct = { ...this.itemProduct.data }
      itemProduct.attributes.customAttributes.files = this.files
      await this.itemProductUpdate(itemProduct)
      this.files = this.createFilesArray()
      this.isLoading = false
      this.isEditable = false
    },
    createFilesArray() {
      return [
        { name: 'Main View', url: null },
        { name: 'Front View', url: null },
        { name: 'Back View', url: null }
      ]
    }
  }
}
</script>

<style scoped></style>
