<template>
  <div v-if="itemProduct">
    <BaseLayoutTitle
      v-if="itemProduct.data"
      :prefix="itemProduct.data.attributes.name"
      :title="itemProduct.data.attributes.customAttributes.code"
    >
      <template v-slot:edit>
        <v-btn :to="{ path: 'edit' }" append color="primary" outlined tile
          ><v-icon left>mdi-clipboard-edit-outline</v-icon>
          {{ $t('actions.edit') }}</v-btn
        ></template
      ></BaseLayoutTitle
    >

    <v-row>
      <v-col cols="12"><ItemProductShowDetails /></v-col>
      <v-col cols="12"
        ><ItemProductRelatedInfo :item-product="itemProduct"
      /></v-col>
      <v-col cols="12"
        ><ItemUnitsOnItemProductIndex :item-product-id="itemProductId"
      /></v-col>
    </v-row>

    <!-- <DocumentIndex :resource-id="itemProductId" resource-type="Item" /> -->
    <BaseScrollUpButton />
  </div>
</template>

<script>
import ItemProductShowDetails from '@/views/item-product/components/ItemProductShowDetails'
import ItemUnitsOnItemProductIndex from '@/views/item-units-on-product/components/ItemUnitsOnItemProductIndex'
import ItemProductRelatedInfo from '@/views/item-product/components/ItemProductRelatedInfo'
// import DocumentIndex from '@/views/document/components/DocumentIndex'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  name: 'ItemProductShow',
  components: {
    ItemProductRelatedInfo,
    ItemProductShowDetails,
    ItemUnitsOnItemProductIndex
    // DocumentIndex
  },
  props: {
    itemProductId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({ itemProduct: 'itemProduct/item' })
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions({ productFetch: 'itemProduct/fetch' }),
    async fetchData() {
      this.isLoading = true
      await this.productFetch(this.itemProductId)
      this.isLoading = false
    }
  }
}
</script>
