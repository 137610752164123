<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    :loading="isLoading"
  >
    <template v-slot:item.attributes.name="{ item }">
      <div class="py-5">{{ item.attributes.name | titleCase }}</div>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip small light text-color="grey darken-2">
        {{ item.attributes.status | titleCase }}</v-chip
      >
    </template>
    <template v-slot:item.supplier>
      Tonewoods S.L
    </template>
    <template v-slot:top>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            Edit Quantity
          </v-card-title>

          <v-card-text>
            <v-container>
              <QuantityField
                :key="key"
                :maximum="true"
                :item="editedItem"
                filled
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="grey lighten-1"
              light
              outlined
              @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn depressed color="primary" @click="onSubmit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:item.actions="{ item }">
      <div class="py-2">
        <v-icon
          small
          color="info"
          class="mx-1"
          @click="
            $router.push({
              name: 'inventory-item-show',
              params: { inventoryItemId: item.id }
            })
          "
          >mdi-eye</v-icon
        >
        <v-icon small color="info" class="mx-1" @click="editItem(item)"
          >mdi-pencil-outline</v-icon
        >
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config'
import QuantityField from '@/components/QuantityField'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
  components: {
    QuantityField
  },
  props: {
    itemProductId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      key: 0,
      dialog: false,
      editedItem: null,
      isLoading: false,
      headers: [
        {
          text: this.$t('common.name'),
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },
        {
          text: this.$t('item.description'),
          value: 'attributes.description',
          sortable: false
        },
        {
          text: this.$tc('item.unit', 2),
          value: 'attributes.itemUnitsResponseCount',
          sortable: false
        },
        {
          text: this.$tc('user.supplier', 1),
          value: 'supplier',
          sortable: false
        },
        {
          text: this.$tc('item.category', 1),
          value: 'attributes.customAttributes.category',
          sortable: false
        },
        {
          text: this.$tc('item.specie', 1),
          value: 'attributes.customAttributes.specie.text',
          sortable: false
        },

        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      items: 'itemUnit/items'
    })
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      await this.filterUnits()
      this.isLoading = false
    },
    async filterUnits() {
      const params = {
        organizationId: ORGANIZATION_ID(),
        productId: this.itemProductId
      }
      return await this.productUnitFilter({
        params,
        attribute: '/units-on-product'
      })
    },
    async editItem(item) {
      this.key = item.id
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.attributes.quantity =
        item.attributes.itemUnitsResponseCount
      this.dialog = true
    },
    async onSubmit() {
      const {
        id,
        attributes: { quantity }
      } = this.editedItem

      const itemUnit = {
        id,
        attributes: {
          quantity,
          productId: this.itemProductId,
          organizationId: ORGANIZATION_ID()
        }
      }
      await this.createItemUnit({
        data: itemUnit,
        attribute: '/units-on-product'
      })
      await this.fetchData()
      this.dialog = false
    },
    ...mapActions({
      productUnitFilter: 'itemUnit/filter',
      createItemUnit: 'itemUnit/update'
    })
  }
}
</script>
