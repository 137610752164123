<template>
  <base-secondary-table-layout :card-title="`${$tc('item.name', 2)}`">
    <template v-slot:button>
      <base-new-outlined-button
        :to="{
          name,
          params: { itemProductId }
        }"
        >{{ $tc('item.name', 1) }}</base-new-outlined-button
      >
    </template>
    <ItemUnitsOnItemProductIndexTable :item-product-id="itemProductId" />
  </base-secondary-table-layout>
</template>

<script>
import ItemUnitsOnItemProductIndexTable from './ItemUnitsOnItemProductIndexTable'
export default {
  components: {
    ItemUnitsOnItemProductIndexTable
  },
  props: {
    itemProductId: { type: String, required: true }
  },
  data() {
    return {
      name: 'item-units-on-product-new'
    }
  }
}
</script>
